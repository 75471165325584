<template>
  <div>
    <b-sidebar
      id="sidebar-footer"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      :visible="sidebarFormActive"
      @change="(val) => $emit('update:sidebar-form-active', val)"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0" v-if="transaction.id !== undefined">
            EDITAR TRANSAÇÃO
          </h5>
          <h5 v-else class="mb-0">ADICIONAR TRANSAÇÃO</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <div class="px-1 mt-2">
          <b-form>
            <b-row class="mt-1">
              <b-col sm="12">
                <b-form-group label="Título" label-for="title">
                  <b-form-input
                    :id="'title'"
                    v-model="transactionLocal.title"
                    type="text"
                    placeholder="Título da transação"
                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Status">
                  <v-select
                    v-model="transactionLocal.status"
                    :options="statusOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Valor" label-for="value">
                  <money
                    :id="'value'"
                    class="form-control"
                    v-model="transactionLocal.value"
                    type="text"
                    placeholder="Valor da transação"
                    v-bind="money"
                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Tipo de transação" label-for="type">
                  <v-select
                    v-model="transactionLocal.type"
                    :options="typeOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Data de vencimento" label-for="dueDate">
                  <flat-pickr
                    :id="'dueDate'"
                    class="form-control"
                    :config="dateConfig"
                    v-model="transactionLocal.dueDate"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Descrição" label-for="description">
                  <b-form-textarea
                    :id="'description'"
                    v-model="transactionLocal.description"
                    type="text"
                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </template>
      <template #footer>
        <sidebar-footer
          :transaction="transaction"
          @hide="() => $emit('update:sidebar-form-active', false)"
          @form-submit="submitForm"
          @remove="remove"
        />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BCol,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BRow,
  VBTooltip,
} from "bootstrap-vue";

import SidebarFooter from "./SidebarFooter.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

import { VMoney } from "v-money";
import { Money } from "v-money";

import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    SidebarFooter,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
    flatPickr,
    Money,
  },

  directives: {
    "b-tooltip": VBTooltip,
    money: VMoney,
  },

  props: {
    sidebarFormActive: {
      type: Boolean,
      required: true,
    },
    transaction: {
      type: Object,
      required: true,
    },
  },

  data() {
    const transaction = {
      title: "",
      type: "",
      value: "",
      status: "",
      dueDate: "",
      description: "",
    };

    return {
      price: 123.45,
      transactionLocal: Object.assign(transaction, this.transaction),
      statusOptions: [
        { label: "Pago", value: 1 },
        { label: "Pendente", value: 0 },
        { label: "Expirado", value: -1 },
      ],
      typeOptions: [
        { label: "Entrada", value: 1 },
        { label: "Saída", value: 0 },
      ],
      dateConfig: {
        altInput: true,
        altFormat: "d \\de M\\. \\de Y",
        dateFormat: "Y-m-d",
        locale: Portuguese,
        allowInput: false,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
    };
  },

  watch: {
    sidebarFormActive() {
      this.transactionLocal = Object.assign({}, this.transaction);
    },
  },

  methods: {
    async remove() {
      try {
        const action = await this.$bvModal.msgBoxConfirm(
          `Tem certeza que deseja remover esta transação?`,
          {
            title: "Tem certeza desta ação?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Sim",
            cancelTitle: "Não",
            cancelVariant: "outline-secondary",
            hideHeaderClose: true,
            centered: true,
          }
        );

        if (!action) return;

        await store.dispatch("financial/delete", this.transactionLocal.id);
        this.$emit("update:sidebar-form-active", false);
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              err.response != undefined
                ? err.response.data.message
                : `Erro ao remover a transação`,
            icon: "UserIcon",
            variant: "danger",
          },
        });
      }
    },

    async submitForm() {
      try {
        const data = Object.assign({}, this.transactionLocal);
        let message = `Transação adicionada com sucesso`;

        if (Object.keys(this.transaction).length) {
          await store.dispatch("financial/update", data);
          message = `Transação editada com sucesso`;
        } else {
          await store.dispatch("financial/add", data);
        }

        await store.dispatch("financial/fetch");
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: "UserIcon",
            variant: "success",
          },
        });
        this.$emit("update:sidebar-form-active", false);
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              err.response != undefined
                ? err.response.data.message
                : `Erro ao editar a transação`,
            icon: "UserIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
