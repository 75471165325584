<template>
  <div class="pl-1 py-1">
    <b-row class="mb-2">
      <b-col>
        <b-button
          block
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          @click="() => $emit('update:sidebar-form-active', true)"
          variant="outline-primary"
        >
          ADICIONAR
        </b-button>
      </b-col>
    </b-row>
    <b-row class="my-2" v-if="filter != ''">
      <b-col>
        <b-button
          block
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          @click="() => $emit('change-status', '')"
          variant="outline-warning"
        >
          LIMPAR FILTROS
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card
          bg-variant="success"
          text-variant="white"
          class="w-100 cursor-pointer"
          @click="$emit('change-status', 'entrada')"
        >
          <b-card-title class="text-white mb-0">
            <div class="mb-50">Entrada</div>
            <h4 class="text-white">
              R$ {{ $options.filters.formatMoney(entradas) }}
            </h4>
          </b-card-title>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card
          bg-variant="warning"
          text-variant="white"
          class="w-100 cursor-pointer"
          @click="$emit('change-status', 'saida')"
        >
          <b-card-title class="text-white mb-0">
            <div class="mb-50">Saída</div>
            <h4 class="text-white">
              R$ {{ $options.filters.formatMoney(saidas) }}
            </h4>
          </b-card-title>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BButton, BRow, BCol, BCard, BCardTitle } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardTitle,
  },

  props: {
    items: {
      type: Array,
      require: true,
    },
    filter: {
      type: String,
      require: true,
    },
  },

  computed: {
    entradas() {
      return this.items
        .filter((transacao) => transacao.type === 1)
        .reduce((sum, transacao) => sum + parseInt(transacao.value), 0);
    },

    saidas() {
      return this.items
        .filter((transacao) => transacao.type === 0)
        .reduce((sum, transacao) => sum + parseInt(transacao.value), 0);
    },
  },
};
</script>

<style></style>
