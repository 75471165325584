<template>
  <div class="p-2 border-top">
    <b-row class="mb-1 text-center" v-if="Object.keys(transaction).length > 0">
      <b-col>
        <small class="font-weight-bold"
          >Transação adicionada em
          {{ transaction.createdAt | moment("DD [de] MMM. [de] YYYY") }}</small
        >
      </b-col>
    </b-row>
    <div class="d-flex flex-row justify-content-between">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :variant="'outline-success'"
        @click="$emit('form-submit')"
        >Salvar
      </b-button>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        @click="$emit('remove')"
        variant="outline-danger"
      >
        Remover
      </b-button>
      <b-button
        v-if="false"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        @click="$emit('hide')"
        variant="outline-warning"
        class="d-none d-md-block"
      >
        Cancelar
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton, BRow, BCol } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
    BRow,
    BCol,
  },

  directives: {
    Ripple,
  },

  props: {
    transaction: {
      type: Object,
      require: true,
    },
  },
};
</script>
