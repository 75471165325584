<template>
  <div v-if="items.length">
    <b-card>
      <b-card-body>
        <vue-paginate-scroll
          :src="items"
          :per-scroll="perPage"
          :observerOptions="{ threshold: 0.75 }"
        >
          <template slot-scope="{ data }">
            <app-timeline class="cursor-pointer">
              <app-timeline-item
                icon="DollarSignIcon"
                v-for="(transacao, key) in data"
                v-bind:key="key"
                :variant="transacao.type === 1 ? 'success' : 'warning'"
                @click="() => openTransaction(transacao)"
              >
                <div
                  class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                >
                  <h6>
                    R$
                    {{ $options.filters.formatMoney(transacao.value) }}
                    - {{ transacao.title }}
                  </h6>
                  <small
                    class="font-small-3"
                    :class="
                      transacao.type === 1 ? 'text-success' : 'text-warning'
                    "
                    >{{ transacao.createdAt | moment("DD/MM/YYYY - H:mm") }}
                    <br />
                    <span
                      class="font-weight-bold text-danger"
                      v-if="transacao.status === -1"
                      >PAGAMENTO EXPIRADO</span
                    >
                    <span
                      class="font-weight-bold text-warning"
                      v-else-if="transacao.status === 0"
                      >PAGAMENTO PENDENTE</span
                    >
                    <span
                      class="font-weight-bold text-success"
                      v-else-if="transacao.status === 1"
                      >PAGAMENTO REALIZADO</span
                    >
                  </small>
                </div>
                <p
                  v-if="transacao.dueDate"
                  class="font-weight-bold font-italic"
                >
                  Vencimento:
                  <span>{{ transacao.dueDate | moment("DD/MM/YYYY") }}</span>
                </p>
                <p v-if="transacao.client">
                  <span class="font-weight-bold">{{
                    transacao.client.name
                  }}</span>
                  <br />
                  <span class="font-italic">{{ transacao.client.email }}</span>
                </p>
                <div
                  v-html="transacao.description"
                  v-if="transacao.description"
                ></div>
              </app-timeline-item>
            </app-timeline>
          </template>
        </vue-paginate-scroll>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardBody, VBToggle, VBTooltip } from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";

export default {
  name: "Timeline",
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
    BCardBody,
  },

  props: {
    items: {
      type: Array,
      require: true,
    },
    transaction: {
      type: Object,
      require: true,
    },
  },

  data() {
    return {
      currentPage: 1,
      perPage: 15,
    };
  },

  methods: {
    openTransaction(transacao) {
      this.$emit("update:transaction", transacao);
      this.$emit("update:sidebar-form-active", true);
    },
  },

  directives: { "b-toggle": VBToggle, "b-tooltip": VBTooltip, Ripple },
};
</script>
