<template>
  <div>
    <form-sidebar
      :sidebarFormActive.sync="sidebarFormActive"
      :transaction="transaction"
    />
    <b-row>
      <b-col md="3">
        <!-- Sidebar -->
        <div
          class="financial-sidebar"
          :class="{ show: isOverlaySidebarActive }"
        >
          <sidebar-category
            @change-status="changeStatus"
            :items="transacoes"
            :filter="statusFilter"
            :is-overlay-sidebar-active.sync="isOverlaySidebarActive"
            :sidebarFormActive.sync="sidebarFormActive"
          />
        </div>
      </b-col>
      <b-col md="9">
        <div class="card-body pb-0">
          <timeline
            :items="items"
            :transaction.sync="transaction"
            :sidebarFormActive.sync="sidebarFormActive"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import moment from "moment";
import SidebarCategory from "./sidebar-category/Sidebar";
import FormSidebar from "./form-sidebar/FormSidebar";
import Timeline from "./Timeline";

export default {
  components: {
    BRow,
    BCol,
    SidebarCategory,
    Timeline,
    FormSidebar,
  },

  data() {
    return {
      sidebarFormActive: false,
      isOverlaySidebarActive: true,
      transaction: {},
      statusFilter: "",
      startDate: "",
      endDate: "",
    };
  },

  computed: {
    transacoes() {
      return this.$store.state.financial.list;
    },

    items() {
      let transacoes = this.transacoes;

      if (this.statusFilter) {
        let type = 1;
        if (this.statusFilter == "saida") type = 0;
        transacoes = transacoes.filter((transacao) => transacao.type == type);
      }
      return transacoes.sort(this.sortDate);
    },

    rows() {
      return this.transacoes.length;
    },
  },

  watch: {
    sidebarFormActive(active) {
      if (!active) setTimeout(() => (this.transaction = {}), 200);
    },
  },

  methods: {
    fetchData() {
      this.$store.dispatch("financial/fetch", {
        dates: { start: this.startDate, end: this.endDate },
        filtro: this.statusFilter,
      });
    },

    changeStatus(status) {
      this.statusFilter = status;
      this.isOverlaySidebarActive = false;
    },

    sortDate(a, b) {
      let created_atA = moment(b.date);
      let created_atB = moment(a.date);

      if (created_atA.unix() < created_atB.unix()) return -1;
      if (created_atA.unix() > created_atB.unix()) return 1;
      return 0;
    },
  },

  async created() {
    await this.fetchData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/apps/calendar.scss";
</style>
